<template>
<div>
  <div class="container-fluid">
    <PageTitle :noAdd="true" :singlePage="true" />
    
    <b-overlay
      :show="loadingKhusus"
      rounded="sm"
      z-index="50"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
        <form @submit.prevent="handleSubmit(doSubmitRisiko)">
          <b-card title="Register Risiko">
            <b-overlay :show="!row.trp_office_code" rounded="sm">
              <b-alert 
                v-if="row.trp_approve_status=='R' && moduleRole('isRiskOfficer')"
                show
                variant="danger"
              >
                <span style="font-weight: 700;">Catatan Revisi : </span>
                {{row.trp_approve_reason}}
              </b-alert>

              <VStepTab 
                :step.sync="stepTab" 
                :last="stepLast" 
                :config="stepValidate"
              >
                <template #link>
                  <VStepLink :step.sync="stepTab" :option="1">
                    Register Risiko
                  </VStepLink>
                  <VStepLink :step.sync="stepTab" :option="2">
                    Pilih Risiko
                  </VStepLink>
                  <VStepLink :step.sync="stepTab" :option="3">
                    Sebab Dampak &amp; Pengendalian
                  </VStepLink>
                  <VStepLink :step.sync="stepTab" :option="4">
                    Data Register Risiko
                  </VStepLink>
                </template>
                <template #content>
                  <VStepContent :step.sync="stepTab" :option="1">
                    <template #title>
                      Register Risiko
                    </template>
                    <template #fieldset>
                      <b-row>
                        <b-col md=6>
                          <b-form-group label="Tahun">
                            <b-input 
                              :value="row.trp_tahun"
                              :readonly="true"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md=6>
                          <b-form-group 
                            v-if="row.trp_is_periode=='triwulan'"
                            label="Triwulan"
                          >
                            <b-input 
                              :value="row.trp_triwulan"
                              :readonly="true"
                            />
                          </b-form-group>
                          <b-form-group 
                            v-else
                            label="Bulan"
                          >
                            <b-input 
                              :value="'2021-'+row.trp_bulan+'-01' | moment('MMMM')"
                              :readonly="true"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md=6>
                          <b-form-group label="Kantor">
                            <template v-if="moduleRole('isAdministratorSystem') || moduleRole('isAuditor')">
                              <VSelect 
                                v-model="row.trp_office_code" 
                                placeholder="-- Pilih Satu --"
                                :options="mrKantor" 
                                :reduce="v=>v.value"
                              />
                              <VValidate 
                                v-show="showError"
                                name="Kantor" 
                                v-model="row.trp_office_code"
                                rules="required" 
                              />
                            </template>
                            <template v-else>
                              <b-input 
                                :value="mrValue(mrKantor, row.trp_office_code)"
                                :readonly="true"
                              />
                            </template>
                          </b-form-group>
                        </b-col>
                        <b-col md=6>
                          <b-form-group :label="uLevel==='pusat' ? 'Group' : 'Unit Kerja'">
                            <VSelect 
                              v-model="row.trp_unit_kerja" 
                              placeholder="-- Pilih Satu --"
                              :options="mrUnitKerjaPerKantor" 
                              :disabled="isBelumTerpenuhi"
                              :reduce="v=>v.value"
                            />
                            <VValidate 
                              v-show="showError"
                              name="Unit Kerja" 
                              v-model="row.trp_unit_kerja"
                              rules="required" 
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </template>
                  </VStepContent>
                  <VStepContent :step.sync="stepTab" :option="2">
                    <template #title>
                      Pilih Risiko
                    </template>
                    <template #fieldset>
                      <div class="wrap_info_preview">
                        <b-row>
                          <b-col md=2>
                            <div class="wrap_preview_prof">
                              <h3>Periode</h3>
                              <p v-if="row.trp_is_periode=='triwulan'"> 
                                Triwulan ke-{{ row.trp_triwulan }} {{ row.trp_tahun }}
                              </p>
                              <p v-else>
                                {{ '2021-'+row.trp_bulan+'-01' | moment('MMMM') }}-{{ row.trp_tahun }}
                              </p>
                            </div>
                          </b-col>
                          <b-col md=5>
                            <div class="wrap_preview_prof">
                              <h3>Kantor</h3>
                              <p> {{ mrValue(mrKantor, row.trp_office_code) }}</p>
                            </div>
                          </b-col>
                          <b-col md=5>
                            <div class="wrap_preview_prof">
                              <h3>Unit Kerja</h3>
                              <p> {{ mrValue(mrUnitKerja, row.trp_unit_kerja) }}</p>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <b-card no-body>
                        <b-card-header class="bg-white pl-0 pr-0">
                          <b-row>
                            <b-col md=3>
                              <b-button
                                variant="info"
                                @click="doOpenModal(mrSasaranRtu, 'SasaranRTUModal')"
                              >
                                <b-icon icon="eye"></b-icon> Daftar Sasaran RTU
                              </b-button>
                            </b-col>
                            <b-col cols="auto">
                              <div class="lbl_info_total">
                                Jumlah Risiko Terpilih: {{ risiko_selected.length }}
                              </div>
                            </b-col>
                            <b-col md=6>
                              <b-input-group>
                                <b-input 
                                  v-model="t2_search"
                                  placeholder="Ketik untuk mencari ..."
                                  v-on:keyup.enter="getTab2('search')"
                                />
                                <b-input-group-append>
                                  <b-button
                                    variant="info"
                                    size="sm"
                                    class="btn-icon"
                                    @click="getTab2('search')"
                                  >
                                    <i class="icon-magnifier"></i>
                                  </b-button>
                                </b-input-group-append>
                                <b-input-group-append>
                                  <b-button
                                    variant="secondary"
                                    size="sm"
                                    class="btn-icon"
                                    @click="()=>{ t2_search=''; getTab2('search'); }"
                                  >
                                    Reset
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-col>
                          </b-row>
                        </b-card-header>
                        <b-table
                          id="my-table2"
                          
                          :fields="TFieldRisiko"
                          :busy="!t2_risiko.data||t2_load"
                          :items="t2_risikoData"

                          small
                          show-empty
                          thead-tr-class="full-color-table full-purple-table"
                          bordered
                          responsive
                        >
                          <template #empty>
                            <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                          </template>
                          <template #head(select)>
                            <b-form-checkbox
                              :checked="TRisikoSelect=='Y'"
                              @change="doBindCKRoot"
                            />
                          </template>
                          <template #cell(bidang)="data">
                            {{data.value || '-'}}
                          </template>
                          <template #cell(select)="data">
                            <b-form-checkbox
                              v-model="data.item.is_selected"
                              value="Y"
                              unchecked-value="N"
                              :disabled="data.item.is_auto_select=='Y'"
                              @change="doBindCKSub(data.item)"
                            />
                          </template>
                        </b-table>
                        
                        <b-row>
                          <b-col v-if="t2_risiko.data">
                            <b-pagination
                              v-model="t2_page"
                              v-if="t2_risiko.total >= 20"
                              :total-rows="t2_risiko.total"
                              :per-page="20"
                              aria-controls="my-table2"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </b-card>
                    </template>
                  </VStepContent>
                  <VStepContent :step.sync="stepTab" :option="3">
                    <template #title>
                      Sebab Dampak &amp; Pengendalian
                    </template>
                    <template #fieldset>
                      <div class="wrap_info_preview">
                        <b-row>
                          <b-col md=2>
                            <div class="wrap_preview_prof">
                              <h3>Periode</h3>
                              <p v-if="row.trp_is_periode=='triwulan'"> 
                                Triwulan ke-{{ row.trp_triwulan }} {{ row.trp_tahun }}
                              </p>
                              <p v-else>
                                {{ '2021-'+row.trp_bulan+'-01' | moment('MMMM') }}-{{ row.trp_tahun }}
                              </p>
                            </div>
                          </b-col>
                          <b-col md=5>
                            <div class="wrap_preview_prof">
                              <h3>Kantor</h3>
                              <p> {{ mrValue(mrKantor, row.trp_office_code) }}</p>
                            </div>
                          </b-col>
                          <b-col md=5>
                            <div class="wrap_preview_prof">
                              <h3>Unit Kerja</h3>
                              <p> {{ mrValue(mrUnitKerja, row.trp_unit_kerja) }}</p>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <b-card no-body>
                        <b-card-header class="bg-white pl-0 pr-0">
                          <b-row>
                            <b-col md=6></b-col>
                            <b-col md=6>
                              <b-input-group>
                                <b-input 
                                  v-model="t3_search"
                                  placeholder="Ketik untuk mencari ..."
                                  v-on:keyup.enter="getTab3('search')"
                                />
                                <b-input-group-append>
                                  <b-button
                                    variant="info"
                                    size="sm"
                                    class="btn-icon"
                                    @click="getTab3('search')"
                                  >
                                    <i class="icon-magnifier"></i>
                                  </b-button>
                                </b-input-group-append>
                                <b-input-group-append>
                                  <b-button
                                    variant="secondary"
                                    size="sm"
                                    class="btn-icon"
                                    @click="()=>{ t3_search=''; getTab3('search'); }"
                                  >
                                    Reset
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-col>
                          </b-row>
                        </b-card-header>

                        <b-table
                          id="my-table"

                          :fields="TFieldRisikoTab3"
                          :busy="!t3_risiko.data||t3_load"
                          :items="t3_risikoData"
                          
                          small
                          show-empty
                          bordered
                          responsive
                        >
                          <template #empty>
                            <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                          </template>
                          <template #thead-top>
                            <b-tr>
                              <b-th 
                                colspan="2" 
                                class="full-color-table full-purple-table" 
                              />
                              <b-th 
                                colspan="3" 
                                class="full-color-table full-purple-table">
                                <center>Jumlah Data</center>
                              </b-th>
                            </b-tr>
                          </template>
                          <template #cell(sebab)="data">
                            <a 
                              class="link_cta_add"
                              href="javascript:;"
                              @click="doOpenModal(data.item, 'sebab')"
                            >
                              <template v-if="DataSubRisiko(data.value,'').length">
                                <b-icon icon="eye"/>
                                {{ DataSubRisiko(data.value,'').length }}
                              </template>
                              <template v-else>
                                <i class="ti-plus"></i>Tambah 
                              </template>
                            </a>
                          </template>
                          <template #cell(dampak)="data">
                            <a 
                              class="link_cta_add"
                              href="javascript:;"
                              @click="doOpenModal(data.item, 'dampak')"
                            >
                              <template v-if="DataSubRisiko(data.value,'').length">
                                <b-icon icon="eye"/>
                                {{ DataSubRisiko(data.value,'').length }}
                              </template>
                              <template v-else>
                                <i class="ti-plus"></i>Tambah 
                              </template>
                            </a>
                          </template>
                          <template #cell(kendali)="data">
                            <a 
                              class="link_cta_add"
                              href="javascript:;"
                              @click="doOpenModal(data.item, 'kendali')"
                            >
                              <template v-if="DataSubRisiko(data.value,'').length">
                                <b-icon icon="eye"/>
                                {{ DataSubRisiko(data.value,'').length }}
                              </template>
                              <template v-else>
                                <i class="ti-plus"></i>Tambah 
                              </template>
                            </a>
                          </template>
                          <!-- <template #cell(aksi)="data">
                            <span v-if="risiko_selected.length<=1 || data.item.is_auto_select=='Y'">
                              -
                            </span>
                            <div 
                              v-else
                              class="btn-toolbar"
                            >
                              <button 
                                type="button" 
                                style="vertical-align:center" 
                                class="btn btn-secondary"
                                @click="data.item.is_selected='N'"
                              >
                                <i class="ti-trash"></i>
                              </button>
                            </div>
                          </template> -->
                        </b-table>
                        <template v-if="t3_risiko.data">
                          <b-pagination
                            v-model="t3_page"
                            v-if="t3_risiko.total >= 20"
                            :total-rows="t3_risiko.total"
                            :per-page="20"
                            aria-controls="my-table"
                          ></b-pagination>
                        </template>
                      </b-card>
                    </template>
                  </VStepContent>
                  <VStepContent :step.sync="stepTab" :option="4">
                    <template #title>
                      Data Register Risiko
                    </template>
                    <template #fieldset>
                      <div 
                        class="wrap_info_risiko_detail"
                        v-for="(v,k) in t4_risikoData"
                        :key="k"
                      >
                        <div class="table-responsive">
                          <table class="table no-border">
                            <tbody>
                              <tr>
                                <td class="align-top font-weight-bold" rowspan="8" style="width: 30px;">
                                  <div class="list_numb_circle">{{k+1}}</div>
                                </td>
                                <td class="font-weight-bold" style="width:20%">Sasaran RTU</td>
                                <td>{{v.sasaran_rtu}}</td>
                              </tr>
                              <tr>
                                <td class="font-weight-bold">Nama Risiko</td>
                                <td>{{v.nama}}</td>
                              </tr>
                              <tr v-if="DataSubRisiko(v.sebab,'').length">
                                <td class="font-weight-bold align-top">Risiko Sebab</td>
                                <td>
                                  <ol 
                                    class="list mb-0" 
                                    style="padding-left: 15px;"
                                  >
                                    <li
                                      v-for="(v2,k2) in DataSubRisiko(v.sebab,'')"
                                      :key="k+'.sebab.'+k2"
                                      class="m-0"
                                    >
                                      <span>{{v2.nama}}</span>
                                    </li>
                                  </ol>
                                </td>
                              </tr>
                              <tr v-if="DataSubRisiko(v.dampak,'').length">
                                <td class="font-weight-bold align-top">Risiko Dampak</td>
                                <td>
                                  <ol 
                                    class="list mb-0" 
                                    style="padding-left: 15px;"
                                  >
                                    <li
                                      v-for="(v2,k2) in DataSubRisiko(v.dampak,'')"
                                      :key="k+'.dampak.'+k2"
                                      class="m-0"
                                    >
                                      <span>{{v2.nama}}</span>
                                    </li>
                                  </ol>
                                </td>
                              </tr>
                              <tr v-if="DataSubRisiko(v.kendali,'').length">
                                <td class="font-weight-bold align-top">Risiko Pengendalian</td>
                                <td>
                                  <ol 
                                    class="list mb-0" 
                                    style="padding-left: 15px;"
                                  >
                                    <li
                                      v-for="(v2,k2) in DataSubRisiko(v.kendali,'')"
                                      :key="k+'.kendali.'+k2"
                                      class="m-0"
                                    >
                                      <span>{{v2.nama}}</span>
                                    </li>
                                  </ol>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div v-if="t4_risiko.data">
                        <b-pagination
                          v-model="t4_page"
                          v-if="t4_risiko.total >= 20"
                          :total-rows="t4_risiko.total"
                          :per-page="20"
                        ></b-pagination>
                      </div>
                      <div class="wrap_info_risiko_detail">
                        <div class="form-group mb-1">
                          <label class="control-label">
                            Status 
                            <span class="text-danger mr5">*</span> 
                          </label>
                          <div>
                            <InputRadio v-model="row.trp_is_draft" name="status" option="Y" label="Draft" />
                            <InputRadio v-model="row.trp_is_draft" name="status" option="N" label="Kirim" class="ml-5" />
                          </div>
                          <VValidate name="Status" v-model="row.trp_is_draft" rules="required" />
                        </div>
                      </div>
                    </template>
                  </VStepContent>
                </template>
                <template #action>
                  <ul>
                    <li 
                      v-if="stepTab>1"
                      @click="doPrevTab"
                    >
                      <button type="button" class="btn btn-rounded btn-previous">
                        Sebelumnya
                      </button>
                    </li>
                    <li @click="doNextTab">
                      <button type="button" class="btn btn-rounded btn-info">
                        {{stepTab==4?'Kirim':'Selanjutnya'}}
                      </button>
                    </li>
                  </ul>
                </template>
              </VStepTab>
            </b-overlay>
          </b-card>
        </form>
      </ValidationObserver>
    </b-overlay>
    
  </div>

  <b-modal 
    id="SasaranRTUModal" 
    title="Daftar Sasaran RTU"
    :ok-only="true"
    ok-title="Tutup"
  >
    <div class="table-responsive">
      <table class="table m-b-0  table-bordered">
        <thead>
          <tr class="full-color-table full-purple-table">
            <th>#</th>
            <th>Nama Sasaran RTU</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v,k) in mrSasaranRtu" :key="k">
            <td>{{ k+1 }}</td>
            <td>{{ v.label }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-modal>

  <b-modal 
    id="RisikoModal" 
    :title="modalTitle"
    :dialog-class="(modalTab=='pilih_risiko'?'modal_xl_custom':'')"
    size="xl"
    ok-variant="info"
    ok-title="Simpan" 
    cancel-title="Batal"
    :ok-only="modalTab!='add_risiko'"
    :hide-header-close="modalTab=='add_risiko'"
    :busy="loadingOverlay"
    no-close-on-backdrop
    no-close-on-esc
    @ok="doOKModal"
    @cancel="doCancelModal"
    @close="doCancelModal"
  >
    <b-overlay :show="loadingOverlay" rounded="sm">
      <template v-if="Object.keys(modal).length">
        
        <template v-if="modalTab=='add_risiko'">
          <ValidationObserver v-slot="{ handleSubmit }" ref="VForm2">
            <form @submit.prevent="handleSubmit(doAddRisiko)">
              <b-form-group
                label="Kategori *"
              >
                <VSelect 
                  v-model="input.category" 
                  placeholder="-- Pilih Satu --"
                  :options="getMrCategory(tabRisiko)" 
                  :reduce="v=>v.value" 
                />
                <VValidate 
                  name="Kategori" 
                  :value="input.category||''"
                  rules="required" 
                />
              </b-form-group>
              <b-form-group
                label="Deskripsi *"
              >
                <b-input
                  v-model="input.name"
                />
                <VValidate 
                  name="Deskripsi" 
                  :value="input.name||''"
                  rules="required" 
                />
              </b-form-group>
              <b-form-group
                v-if="tabRisiko=='kendali'"
                label="Terkait *"
              >
                <VSelect 
                  v-model="input.terkait" 
                  placeholder="-- Pilih Satu --"
                  :options="mrTerkait" 
                  :reduce="v=>v.value" 
                />
                <VValidate 
                  name="Terkait" 
                  :value="input.terkait||''"
                  rules="required" 
                />
              </b-form-group>
            </form>
          </ValidationObserver>
        </template>

        <template v-else-if="modalTab=='pustaka_risiko'">
          <b-card no-body>
            <b-card-header class="bg-white pl-0 pr-0">
              <b-row>
                <b-col md=7>
                  <b-button 
                    variant="info"
                    @click="modalTab='add_risiko'"
                  >
                    <i class="fa fa-plus-circle"></i> Tambah Data Baru
                  </b-button>
                </b-col>
                <b-col>
                  <b-input-group>
                    <b-input 
                      v-model="MTRSearch.pustaka_risiko"
                      placeholder="Ketik untuk mencari ..."
                    />
                    <b-input-group-append>
                      <b-button
                        variant="info"
                        size="sm"
                        class="btn-icon"
                      >
                        <i class="icon-magnifier"></i>
                      </b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button
                        variant="secondary"
                        size="sm"
                        class="btn-icon"
                        @click="MTRSearch.pustaka_risiko=''"
                      >
                        Reset
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card-header>
            <b-table
              id="my-table4"
              :fields="tabRisiko=='kendali'?MTRField.kendali:MTRField.pustaka_risiko"
              :items="DataSubRisiko(modal[tabRisiko]||[], 'pustaka_risiko', ['Y','N'])"
              :per-page="MTRPerPage.pustaka_risiko"
              :current-page="MTRCurrentPage.pustaka_risiko"
              small
              show-empty
              thead-tr-class="full-color-table full-purple-table"
              bordered
              responsive
            >
              <template #empty>
                <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
              </template>
              <template #cell(number)="data">
                {{ data.index+1 }}
              </template>
              <template #cell(terkait)="data">
                {{ data.value=='sebab'?'kemungkinan':(data.value||'-') }}
              </template>
              <template #cell(aksi)="data">
                <template v-if="data.item.is_selected=='Y'">
                  <span class="label label-purple">Telah dipilih</span>
                  <button 
                    type="button" 
                    style="vertical-align:center" 
                    class="btn btn-secondary ml5"
                    @click="doUnSelectRisiko(data,tabRisiko)"
                  >
                    <i class="ti-trash"></i>
                  </button>
                </template>
                <template v-else>
                  <button 
                    type="button" 
                    style="vertical-align:center" 
                    class="btn btn-secondary"
                    @click="doSelectRisiko(data,tabRisiko)"
                  >
                    <i class="ti-plus"></i> Pilih Data
                  </button>
                </template>
              </template>
            </b-table>
            <b-pagination
              v-if="DataSubRisiko(modal[tabRisiko]||[], 'pustaka_risiko', ['Y','N']).length >= MTRPerPage.pustaka_risiko"
              v-model="MTRCurrentPage.pustaka_risiko"
              :total-rows="DataSubRisiko(modal[tabRisiko]||[], 'pustaka_risiko', ['Y','N']).length"
              :per-page="MTRPerPage.pustaka_risiko"
              aria-controls="my-table4"
            ></b-pagination>
          </b-card>
        </template>

        <template v-else>
          <div class="wrap_info_preview">
            <b-row>
              <b-col md=2>
                <div class="wrap_preview_prof">
                  <h3>Periode</h3>
                  <p v-if="row.trp_is_periode=='triwulan'"> 
                    Triwulan ke-{{ row.trp_triwulan }} {{ row.trp_tahun }}
                  </p>
                  <p v-else>
                    {{ '2021-'+row.trp_bulan+'-01' | moment('MMMM') }}-{{ row.trp_tahun }}
                  </p>
                </div>
              </b-col>
              <b-col md=5>
                <div class="wrap_preview_prof">
                  <h3>Kantor</h3>
                  <p> {{ mrValue(mrKantor, row.trp_office_code) }}</p>
                </div>
              </b-col>
              <b-col md=5>
                <div class="wrap_preview_prof">
                  <h3>Unit Kerja</h3>
                  <p> {{ mrValue(mrUnitKerja, row.trp_unit_kerja) }}</p>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <div class="wrap_preview_prof">
                  <h3>Nama Risiko</h3>
                  <p> {{modal.nama}}</p>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <div class="wrap_preview_prof">
                  <h3>Jumlah Sebab</h3>
                  <p> {{modal.sebab.length}}</p>
                </div>
              </b-col>
              <b-col>
                <div class="wrap_preview_prof">
                  <h3>Jumlah Dampak</h3>
                  <p> {{modal.dampak.length}}</p>
                </div>
              </b-col>
              <b-col>
                <div class="wrap_preview_prof">
                  <h3>Jumlah Pengendalian</h3>
                  <p> {{modal.kendali.length}}</p>
                </div>
              </b-col></b-row>

          </div>

          <VTab :justify="true" class="mt-3">
            <template slot="link">
              <VTabLink :step.sync="tabRisiko" option="sebab">Register Risiko Sebab</VTabLink>
              <VTabLink :step.sync="tabRisiko" option="dampak">Register Risiko Dampak</VTabLink>
              <VTabLink :step.sync="tabRisiko" option="kendali">Register Risiko Pengendalian</VTabLink>
            </template>
            <template slot="content">
              <VTabContent :step.sync="tabRisiko" option="sebab">
                <b-card no-body>
                  <b-card-header class="bg-white">
                    <b-row align-h="between">
                      <b-col cols="auto">
                        <b-button-group>
                          <b-button 
                            variant="warning"
                            @click="modalTab='pustaka_risiko'"
                          >
                            <i class="icon-list"></i> Tambah Dari Pustaka
                          </b-button>
                          
                        </b-button-group>
                      </b-col>
                      <b-col md="5">
                        <b-input-group>
                          <b-input 
                            v-model="MTRSearch.sebab"
                            placeholder="Ketik untuk mencari ..."
                          />
                          <b-input-group-append>
                            <b-button
                              variant="info"
                              size="sm"
                              class="btn-icon"
                            >
                              <i class="icon-magnifier"></i>
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append>
                            <b-button
                              variant="secondary"
                              size="sm"
                              class="btn-icon"
                              @click="MTRSearch.sebab=''"
                            >
                              Reset
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-table
                    id="my-table3"
                    :fields="MTRField.sebab"
                    :items="DataSubRisiko(modal.sebab||[], 'sebab')"
                    :per-page="MTRPerPage.sebab"
                    :current-page="MTRCurrentPage.sebab"
                    small
                    show-empty
                    thead-tr-class="full-color-table full-purple-table"
                    bordered
                    responsive
                  >
                    <template #empty>
                      <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                    </template>
                    <template #cell(number)="data">
                      {{ data.index+1 }}
                    </template>
                    <template #cell(aksi)="data">
                      <button 
                        type="button" 
                        style="vertical-align:center" 
                        class="btn btn-secondary"
                        @click="doUnSelectRisiko(data,'sebab')"
                      >
                        <i class="ti-trash"></i>
                      </button>
                    </template>
                  </b-table>
                  <template #footer>
                    <b-row align-h="between">
                      <!-- <b-col md="2">
                        <b-input-group 
                          v-if="DataSubRisiko(modal.sebab||[], 'sebab').length > MTRPerPage.sebab"
                        >
                          <template #prepend>
                            <b-input-group-text>Show</b-input-group-text>
                          </template>
                          <b-form-select
                            class="w-auto mx-50"
                            v-model="MTRPerPage.sebab"
                            :options="Config.dataShownOptions"
                          />
                        </b-input-group>
                      </b-col> -->
                      <b-col md="auto">
                        <b-pagination
                          v-if="DataSubRisiko(modal.sebab||[], 'sebab').length >= MTRPerPage.sebab"
                          v-model="MTRCurrentPage.sebab"
                          :total-rows="DataSubRisiko(modal.sebab||[], 'sebab').length"
                          :per-page="MTRPerPage.sebab"
                          aria-controls="my-table3"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </VTabContent>
              <VTabContent :step.sync="tabRisiko" option="dampak">
                <b-card no-body>
                  <b-card-header class="bg-white">
                    <b-row align-h="between">
                      <b-col cols="auto">
                        <b-button-group>
                          <b-button 
                            variant="warning"
                            @click="modalTab='pustaka_risiko'"
                          >
                            <i class="icon-list"></i> Tambah Dari Pustaka
                          </b-button>
                          <!-- <b-button 
                            variant="info"
                            @click="modalTab='add_risiko'"
                          >
                            <i class="fa fa-plus-circle"></i> Tambah Data Baru
                          </b-button> -->
                        </b-button-group>
                      </b-col>
                      <b-col md="5">
                        <b-input-group>
                          <b-input 
                            v-model="MTRSearch.dampak"
                            placeholder="Ketik untuk mencari ..."
                          />
                          <b-input-group-append>
                            <b-button
                              variant="info"
                              size="sm"
                              class="btn-icon"
                            >
                              <i class="icon-magnifier"></i>
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append>
                            <b-button
                              variant="secondary"
                              size="sm"
                              class="btn-icon"
                              @click="MTRSearch.dampak=''"
                            >
                              Reset
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-table
                    id="my-table3B"
                    :fields="MTRField.dampak"
                    :items="DataSubRisiko(modal.dampak||[], 'dampak')"
                    :per-page="MTRPerPage.dampak"
                    :current-page="MTRCurrentPage.dampak"
                    small
                    show-empty
                    thead-tr-class="full-color-table full-purple-table"
                    bordered
                    responsive
                  >
                    <template #empty>
                      <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                    </template>
                    <template #cell(number)="data">
                      {{ data.index+1 }}
                    </template>
                    <template #cell(aksi)="data">
                      <button 
                        type="button" 
                        style="vertical-align:center" 
                        class="btn btn-secondary"
                        @click="doUnSelectRisiko(data,'dampak')"
                      >
                        <i class="ti-trash"></i>
                      </button>
                    </template>
                  </b-table>
                  <template #footer>
                    <b-row align-h="between">
                      <!-- <b-col md=2>
                        <b-input-group 
                          v-if="DataSubRisiko(modal.dampak||[], 'dampak').length > MTRPerPage.dampak"
                        >
                          <template #prepend>
                            <b-input-group-text>Show</b-input-group-text>
                          </template>
                          <b-form-select
                            class="w-auto mx-50"
                            v-model="MTRPerPage.dampak"
                            :options="Config.dataShownOptions"
                          />
                        </b-input-group>
                      </b-col> -->
                      <b-col md="auto">
                        <b-pagination
                          v-if="DataSubRisiko(modal.dampak||[], 'dampak').length >= MTRPerPage.dampak"
                          v-model="MTRCurrentPage.dampak"
                          :total-rows="DataSubRisiko(modal.dampak||[], 'dampak').length"
                          :per-page="MTRPerPage.dampak"
                          aria-controls="my-table3B"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </VTabContent>
              <VTabContent :step.sync="tabRisiko" option="kendali">
                <b-card no-body>
                  <b-card-header class="bg-white">
                    <b-row align-h="between">
                      <b-col cols="auto">
                        <b-button-group>
                          <b-button 
                            variant="warning"
                            @click="modalTab='pustaka_risiko'"
                          >
                            <i class="icon-list"></i> Tambah Dari Pustaka
                          </b-button>
                          <!-- <b-button 
                            variant="info"
                            @click="modalTab='add_risiko'"
                          >
                            <i class="fa fa-plus-circle"></i> Tambah Data Baru
                          </b-button> -->
                        </b-button-group>
                      </b-col>
                      <b-col md="5">
                        <b-input-group>
                          <b-input 
                            v-model="MTRSearch.kendali"
                            placeholder="Ketik untuk mencari ..."
                          />
                          <b-input-group-append>
                            <b-button
                              variant="info"
                              size="sm"
                              class="btn-icon"
                            >
                              <i class="icon-magnifier"></i>
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append>
                            <b-button
                              variant="secondary"
                              size="sm"
                              class="btn-icon"
                              @click="MTRSearch.kendali=''"
                            >
                              Reset
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-table
                    id="my-table3A"
                    :fields="MTRField.kendali"
                    :items="DataSubRisiko(modal.kendali||[], 'kendali')"
                    :per-page="MTRPerPage.kendali"
                    :current-page="MTRCurrentPage.kendali"
                    small
                    show-empty
                    thead-tr-class="full-color-table full-purple-table"
                    bordered
                    responsive
                  >
                    <template #empty>
                      <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                    </template>
                    <template #cell(number)="data">
                      {{ data.index+1 }}
                    </template>
                    <template #cell(terkait)="data">
                      {{ data.value=='sebab'?'kemungkinan':(data.value||'-') }}
                    </template>
                    <template #cell(aksi)="data">
                      <button 
                        type="button" 
                        style="vertical-align:center" 
                        class="btn btn-secondary"
                        @click="doUnSelectRisiko(data,'kendali')"
                      >
                        <i class="ti-trash"></i>
                      </button>
                    </template>
                  </b-table>
                  <template #footer>
                    <b-row>
                      <!-- <b-col md=2>
                        <b-input-group 
                          v-if="DataSubRisiko(modal.kendali||[], 'kendali').length > MTRPerPage.kendali"
                        >
                          <template #prepend>
                            <b-input-group-text>Show</b-input-group-text>
                          </template>
                          <b-form-select
                            class="w-auto mx-50"
                            v-model="MTRPerPage.kendali"
                            :options="Config.dataShownOptions"
                          />
                        </b-input-group>
                      </b-col> -->
                      <b-col md="auto">
                        <b-pagination
                          v-if="DataSubRisiko(modal.kendali||[], 'kendali').length >= MTRPerPage.kendali"
                          v-model="MTRCurrentPage.kendali"
                          :total-rows="DataSubRisiko(modal.kendali||[], 'kendali').length"
                          :per-page="MTRPerPage.kendali"
                          aria-controls="my-table3A"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </VTabContent>
            </template>
          </VTab>
        </template>
      </template>
    </b-overlay>
  </b-modal>
</div>
</template>

<script>
//import Gen from '@libs/Gen'
import GlobalVue from "@libs/Global"
import Gen from "@libs/Gen"
import PageTitle from "@view/include/PageTitle"
import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'
import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

let _ = global._
let $ = global.jQuery
let Swal = global.Swal

export default {
  extends: GlobalVue,
  components: { 
    PageTitle, VStepTab, VStepLink, VStepContent,
    VTab, VTabLink, VTabContent
  },
  data() {
    return {
      input:{},
      showError:false,
      loadingKhusus:false,
      register_id:"",

      stepTab: 1,
      stepLast:4,
      stepValidate: {
        disabled: false,
        validate: true,
        refs: this.$refs
      },
      
      modal:{},
      modalTab:'pilih_risiko',
      tabRisiko:'sebab',

      TPerPage:10,
      TSearch:"",
      TCurrentPage:1,
      maxRisiko:10,
      periodeCurrent:false,

      TRisikoSelect:'N',
      TFieldRisiko:[
        { key:'select' },
        { key:'code', label:'Kode Risiko' },
        { key:'sasaran_rtu', label:'Sasaran RTU' },
        { key:'bidang', label:'Bidang' },
        { key:'nama', label:'Nama Risiko' },
      ],
      TFieldRisikoTab3:[
        { key:'sasaran_rtu', label:'Sasaran RTU', thClass:'full-color-table full-purple-table' },
        { key:'nama', label:'Nama Risiko', thClass:'full-color-table full-purple-table' },
        { key:'sebab', thClass:'full-color-table full-purple-table' },
        { key:'dampak', thClass:'full-color-table full-purple-table' },
        { key:'kendali', label:'Pengendalian', thClass:'full-color-table full-purple-table' },
        /* { key:'aksi', thClass:'full-color-table full-purple-table' }, */
      ],

      MTRPerPage:{
        sebab:10,
        dampak:10,
        kendali:10,
        pustaka_risiko:10,
      },
      MTRSearch:{
        sebab:"",
        dampak:"",
        kendali:"",
        pustaka_risiko:"",
      },
      MTRCurrentPage:{
        sebab:1,
        dampak:1,
        kendali:1,
        pustaka_risiko:1,
      },
      MTRField:{
        sebab:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          'aksi',
        ],
        dampak:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          'aksi',
        ],
        kendali:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          { key:'terkait' },
          'aksi',
        ],
        pustaka_risiko:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          {key:'aksi', tdClass:'w_100'},
        ]
      },

      mitigasi:0,
      mrKantor:[],
      mrUnitKerja:[],
      mrSasaranRtu:[],
      mrPustakaRisikoRaw:[],
      mrPustakaRisiko:[],
      mrTerkait:[],
      mrCategory:[],
      ledSelectedIds:[],
      uLevel: '',
      uLevelKantor: '',
      idCreatedByForm:[], // risiko ID created by form,

      // Selected ID
      auto_risiko_selected:[], // auto select Risiko IDS
      risiko_selected:[], // risiko IDS
      sebab_selected:[], // sebab IDS
      dampak_selected:[], // dampak IDS
      kendali_selected:[], // kendali IDS
      t_mitigasi_selected:[], // auto Select Tindakan Mitigasi

      // Data Per Tab
      t2_risiko: {data: false},
      t2_load: false,
      t2_page: 1,
      t2_search: "",

      t3_risiko: {data: false},
      t3_load: false,
      t3_page: 1,
      t3_search: "",

      t4_risiko: {data: false},
      t4_load: false,
      t4_page: 1,
      t4_search: "",
    };
  },
  computed:{
    isBelumTerpenuhi(){
      return (this.row.trp_hold_to_pjrtu=='Y' && this.row.old_trp_is_draft=='N')
    },

    t2_risikoData(){
      return this.t2_risiko.data || []
    },
    t3_risikoData(){
      return this.t3_risiko.data || []
    },
    t4_risikoData(){
      return this.t4_risiko.data || []
    },

    mrUnitKerjaPerKantor(){
      
      if(this.moduleRole('isAdministratorSystem') || this.moduleRole('isAuditor')){
        
        const kantorSelected = this.mrValue(
          this.mrKantor, 
          this.row.trp_office_code, 
          'unit_kerja_ids'
        )
        const unitKerja = _.filter(
          this.mrUnitKerja, 
          (val)=>{
            return (kantorSelected||[]).indexOf(val.value)>-1
          }
        )
        if(unitKerja.length == 0){
          if(this.row.trp_unit_kerja){
            return this.mrUnitKerja.filter(v => v.value == this.row.trp_unit_kerja)
          }else{
            return unitKerja
          }
        }else{
          return unitKerja
        }

      }else{
        return this.mrUnitKerja
      }
      
    },
    modalTitle(){
      if(this.modalTab=='pustaka_risiko'){
        return 'Pilih Risiko'
      }
      else if(this.modalTab=='add_risiko'){
        return 'Tambah Risiko ' + this.tabRisiko
      }else{
        return 'Detail Risiko'
      }
    },
  },
  methods:{
    /* 
      DO NEXT FIRST TAB: 
      - CEK RISIKO DATA 
      - GET AUTO SELECT DARI TRIWULAN/2 BLN SEBELUMNYA
    */
    doPrevTab(){
      this.TPerPage=10
      this.TCurrentPage=1
      this.TSearch=""
      this.$set(this,'stepTab', this.stepTab-1)
    },
    checkTab1(){
      return this.doSubmit(
        "/do/"+this.modulePage, 
        _.assign(this.row, {
          type:'checktab1',
          isAdd:this.isList?'Y':'N'
        }), 
        (type, resp)=>{

          if(type=='success'){
            if(resp.draftExists){
              this.loadingKhusus = false

              // IS ADD & DRAFT IS EXISTS
              return Swal.fire({
                icon: "warning",
                title: "Register Risiko Sudah Terdaftar!",
                confirmButtonText: "Lanjutkan Pengisian"
              }).then(result=>{
                if(result.value){
                  return this.$router.push({
                    name:'RegisterResiko',
                    params:{pageSlug: resp.draftId},
                    query:{skip:'tab1'}
                  })
                }
              })
            }else if(this.isList){
              this.loadingOverlay=true
              this.doAutoSave('redirect')
            }else{
              // Get Next Data
              this.getTab2()
            }
          }else{
            this.loadingKhusus = false
            this.showErrorAfterSubmit(type, resp)
          }
        }
      )
    },
    getTab2(pageType="firstload"){
      if(pageType=='firstload'){
        this.t2_page = 1
        this.t2_search = ""
      }

      this.t2_load = true
      this.doSubmit(
        "/do/"+this.modulePage, 
        _.assign(
          this.row, 
          {
            type:'tab2',
            isAdd:this.isList?'Y':'N',
            risiko_selected: this.risiko_selected,
            pageType: pageType,
            
            search: this.t2_search,
            page: this.t2_page,
          }
        ), 
        (type, resp)=>{
          this.loadingKhusus=false
          this.loadingOverlay=false
          this.t2_load = false

          if(type=='success'){
            if(pageType == 'firstload'){
              this.$set(this, 'auto_risiko_selected', resp.auto_risiko_selected)
              this.$set(this, 'mrSasaranRtu', resp.mrSasaranRtu)
              this.$set(this, 't_mitigasi_selected', resp.tindakanMitigasiIDS)
            }

            if(resp.auto_risiko_selected.length){
              resp.t2_risiko.data = _.map(resp.t2_risiko.data, (v)=>{
                v.is_auto_select = this.auto_risiko_selected.indexOf(v.id) > -1 ? 'Y' : 'N'
                if(v.is_auto_select=='Y') v.is_selected='Y'
                return v
              })
            }

            this.$set(this, 't2_risiko', resp.t2_risiko)
            this.$set(this, 'risiko_selected', resp.risiko_selected)

            if(pageType=='firstload'){
              this.$set(this,'stepTab', this.stepTab+1)
              $('html, body').animate({scrollTop:0}, '500')
            }

          }else{
            this.loadingKhusus = false
            this.showErrorAfterSubmit(type, resp)
          }
        }
      )
    },
    getTab3(pageType='firstload'){
      if(pageType=='firstload'){
        this.t3_page = 1
        this.t3_search = ""
      }

      this.t3_load = true
      Gen.apiRest(
        "/do/"+this.modulePage, 
        {
          data: _.assign(
            this.row, 
            {
              type:'tab3',
              risiko_selected: this.risiko_selected,
              sebab_selected: this.sebab_selected,
              dampak_selected: this.dampak_selected,
              kendali_selected: this.kendali_selected,
              t_mitigasi_selected: this.t_mitigasi_selected,
              mrSasaranRtu: this.mrSasaranRtu,
              pageType: pageType,
              
              search: this.t3_search,
              page: this.t3_page,
            }
          )
        }, 
        'POST'
      )
        .then((res)=>{
          const resp = res.data

          this.t3_load = false
          this.loadingOverlay = false

          if(resp.invalid=='rtu'){
            
            return Swal.fire({
              icon: "warning",
              title: "Semua Sasaran RTU Wajib Dipilih!",
              html:"<p>Minimal harus ada 1 risiko yang terpilih pada masing-masing sasaran. Berikut daftar sasaran RTU yang belum dipilih:</p><ul style='text-align: left;'><li>"+resp.notSelected+"</li></p>",
              confirmButtonText: "Mengerti"
            })

          }else{

            if(pageType == 'firstload'){
              this.$set(this, 'risiko_selected', resp.risiko_selected)
              this.$set(this, 'sebab_selected', resp.sebab_selected)
              this.$set(this, 'dampak_selected', resp.dampak_selected)
              this.$set(this, 'kendali_selected', resp.kendali_selected)

            }

            this.$set(this, 't3_risiko', resp.t3_risiko)

            if(pageType=='firstload'){
              this.$set(this,'stepTab', this.stepTab+1)
              $('html, body').animate({scrollTop:0}, '500')
            }

          }

        }).catch((err)=>{          
          this.t3_load = false
          this.loadingOverlay = false
          if(err){
              err.statusType = err.status;
              err.status = "error";
              err.message = err.response?.data?.message;
              err.messageError = err.message
          }
          this.showErrorAfterSubmit('error', err)
        })

    },
    getTab4(pageType='firstload'){
      if(pageType=='firstload'){
        this.doAutoSave(false, this.stepTab)
        this.t4_page = 1
        this.t4_search = ""
      }

      this.t4_load = true
      Gen.apiRest(
        "/do/"+this.modulePage, 
        {
          data: _.assign(
            this.row, 
            {
              type:'tab4',
              risiko_selected: this.risiko_selected,
              sebab_selected: this.sebab_selected,
              dampak_selected: this.dampak_selected,
              kendali_selected: this.kendali_selected,
              pageType: pageType,
              
              search: this.t4_search,
              page: this.t4_page,
            }
          )
        }, 
        'POST'
      )
        .then((res)=>{
          const resp = res.data

          this.t4_load = false
          this.loadingOverlay = false

          if(resp.invalid=='subincomplete'){
              
            return Swal.fire({
              icon: "warning",
              title: "Data Sebab, dampak dan pengendalian belum dipilih",
              confirmButtonText: "Mengerti"
            })

          }else{

            if(pageType == 'firstload'){
              this.$set(this, 'risiko_selected', resp.risiko_selected)
              this.$set(this, 'sebab_selected', resp.sebab_selected)
              this.$set(this, 'dampak_selected', resp.dampak_selected)
              this.$set(this, 'kendali_selected', resp.kendali_selected)

            }

            this.$set(this, 't4_risiko', resp.t4_risiko)

            if(pageType=='firstload'){
              this.$set(this,'stepTab', this.stepTab+1)
              $('html, body').animate({scrollTop:0}, '500')
            }

          }

        }).catch((err)=>{          
          this.t4_load = false
          this.loadingOverlay = false
          if(err){
              err.statusType = err.status;
              err.status = "error";
              err.message = err.response?.data?.message;
              err.messageError = err.message
          }
          this.showErrorAfterSubmit('error', err)
        })
    },
    doNextTab(){
      this.showError=true
      this.TSearch=''
      if(this.stepTab==1){

        if(this.isList){
          return this.checkTab1()
        }else{
          return this.getTab2()
        }

      }else if(this.stepTab==2){

        if(!this.risiko_selected.length){
          return Swal.fire({
            icon: "warning",
            title: "Belum ada sasaran yang terpilih!",
            confirmButtonText: "Mengerti"
          })
        }

        if(this.row.trp_is_periode=='bulan'){
          const risikoSelected = _.uniq(this.risiko_selected)
          if(this.uLevelKantor=='pusat' && risikoSelected.length < this.maxRisiko){
            return Swal.fire({
              icon: "warning",
              title: "Jumlah risiko yang dipilih harus "+this.maxRisiko+" risiko",
              confirmButtonText: "Mengerti"
            })
          }

          if(risikoSelected.length > this.maxRisiko){
            return Swal.fire({
              icon: "warning",
              title: "Maksimal risiko yang dipilih adalah "+this.maxRisiko+" risiko",
              confirmButtonText: "Mengerti"
            })
          }
        }

        this.loadingOverlay=true
        this.doAutoSave(false, this.stepTab, ()=>{
          this.t3_page = 1
          this.t3_search = ""
          this.getTab3()
        })

        return false;

      }else if(this.stepTab==3){
        // page tab 3
        this.loadingOverlay=true
        this.doAutoSave(false, this.stepTab, ()=>{          
          this.t4_page = 1
          this.t4_search = ""
          this.getTab4() 
        })

        return false;
      }

      this.$refs.VForm.validate().then(success => {
        if (!success) { return; }
        if(this.stepTab==4){
          return Swal.fire({
            icon: 'warning',
            title: this.row.trp_is_draft == 'Y' ? 'Anda Yakin Data Ini Disimpan Sebagai Draft?' : 'Anda Yakin Untuk Mengirim Data Ini ke PJRTU?',
            showCancelButton: true,
            cancelButtonColor: '#3085d6',
            confirmButtonText: this.row.trp_is_draft == 'Y'? 'Ya, buat draft!': 'Ya, Kirim!',
            cancelButtonText: 'Batal',
            customClass: {
              confirmButton: 'btn btn-primary btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then((result) => {
            if(result.value){
              this.doSubmitRisiko()
            }
          })
        }else{
          this.doAutoSave(false, this.stepTab)
        }
        $('html, body').animate({scrollTop:0}, '500');
      });
    },

    doAutoSave(redirect=false, stepTab=false, callback=false){
      stepTab = stepTab||this.stepTab
      let row = _.clone(this.row)
      row.risiko_selected = this.risiko_selected
      row.sebab_selected = this.sebab_selected
      row.dampak_selected = this.dampak_selected
      row.kendali_selected = this.kendali_selected

      Gen.apiRest(
        "/do/"+this.modulePage, 
        {
          data: _.assign(row, {
            type:'autosave',
            register_id:this.register_id,
            pageTab: stepTab
          })
        }, 
        'POST'
      )
        .then((res)=>{
          this.register_id = res.data.register_id
          this.row.trp_is_draft = "Y"

          if(redirect){
            this.loadingOverlay=false
            return this.$router.push({
              name:'RegisterResiko',
              params:{pageSlug: this.register_id},
              query:{skip:'tab1'}
            })
          }

          if(callback){
            return callback()
          }

        }).catch((err)=>{
          console.log(err)
        })
    },

    /* MODAL EVENT */
    doOpenModal(data, tab){
      if(tab=='SasaranRTUModal'){
        this.$bvModal.show('SasaranRTUModal')
      }else{
        this.modalTab="pilih_risiko"
        this.$set(this,'modal', data)
        this.$set(this,'tabRisiko', tab)
        this.$bvModal.show('RisikoModal')
      }
    },
    doOKModal(bvModalEvt) {
      if(this.modalTab=='add_risiko'){
        bvModalEvt.preventDefault()
        this.doAddRisiko(this.modal[this.tabRisiko], this.tabRisiko)
      }else if(this.modalTab=='pustaka_risiko'){
        bvModalEvt.preventDefault()
        this.modalTab='pilih_risiko'

        this.doAutoSave()
      }else if(this.modalTab=='pilih_risiko'){
        this.doAutoSave()
      }
    },
    doCancelModal(bvModalEvt) {
      if(['add_risiko','pustaka_risiko'].indexOf(this.modalTab)>-1){
        bvModalEvt.preventDefault()
        this.modalTab='pilih_risiko'
      }else{
        this.$bvModal.hide('RisikoModal')
      }
    },

    /* FILTER TAB RISIKO DATA */
    DataSubRisiko(data, tab, isSelected=['Y']){
      let dataSelected = _.filter(
        data,
        (v)=>{
          return isSelected.indexOf(v.is_selected)>-1
        }
      )
      if(!this.MTRSearch[tab] || !tab){
         return dataSelected
      }else{
        let TSearch = (this.MTRSearch[tab]||"").toLowerCase()
        return _.filter(
          dataSelected, 
          (v)=>{
            return ( 
              (v.nama||"").toLowerCase().includes(TSearch)  ||
              (v.kategori||"").toLowerCase().includes(TSearch) ||
              (v.code||"").toLowerCase().includes(TSearch)
            )
          }
        )
      }
    },
    getMrCategory(tab){
      let a = tab.toString()
      if(a=='sebab') a = 'penyebab'
      if(a=='kendali') a = 'pengendalian'
      return _.filter(this.mrCategory, (v)=>{
        return v.group==a
      })
    },

    /* DO EVENT */
    doUnSelectRisiko(data, tab){
      let index = _.indexOf(_.map(this.modal[tab], (v)=>{return v.id}), data.item.id)
      this.modal[tab][index].is_selected='N'

      const cek = _.indexOf(this[tab+'_selected'], data.item.id)
      if(cek>-1){
        this[tab+'_selected'].splice(cek, 1)
      }
    },
    doSelectRisiko(data, tab){
      let index = _.indexOf(_.map(this.modal[tab], (v)=>{return v.id}), data.item.id)
      this.modal[tab][index].is_selected='Y'

      const cek = _.indexOf(this[tab+'_selected'], data.item.id)
      if(cek<=-1){
        this[tab+'_selected'].push(data.item.id)
      }
    },
    doAddRisiko(data, t){
      // ADD TO MASTER
      let tab = t.toString()
      if(tab=='sebab') tab = 'MrRisikoSebab'
      else if(tab=='dampak') tab = 'MrRisikoDampak'
      else if(tab=='kendali') tab = 'MrRisikoKendali'

      let input = this.input
      input['risiko_id']=this.modal.id
      input['is_active']=1
      input = _.mapKeys(this.input, (_, k)=>{
        if(t=='sebab') return 'mrs_'+k
        if(t=='kendali') return 'mrk_'+k
        return 'mrd_'+k
      })

      this.doSubmit(
        "/do/"+tab, 
        Object.assign(input, {
          type:'add'
        }), 
        (type, resp)=>{
          if(type=='success'){
            data.push(resp.data.row); this.$set(this,'input',{});
            this.idCreatedByForm.push(resp.data.row.id)
            this.modalTab='pilih_risiko'

            // select
            const cek = _.indexOf(this[t.toString()+'_selected'], resp.data.row.id)
            if(cek<=-1){
              this[t.toString()+'_selected'].push(resp.data.row.id)
            }

            this.doAutoSave()
          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        },
        'POST', 'VForm2'
      )
    },

    // CHECK
    doBindCKRoot(){
      this.TRisikoSelect = this.TRisikoSelect=='Y'?'N':'Y'
      const data = _.map(JSON.parse(JSON.stringify(this.t2_risikoData)), (v2)=>{
        v2['is_selected'] = v2.is_auto_select=='Y'?'Y':this.TRisikoSelect
        return v2
      })
      this.$set(this.t2_risiko, 'data', data)

      if(this.TRisikoSelect=='Y'){
        let selected = _.clone(this.t2_risiko.risikoIDS)
        selected = _.uniq(selected)
        this.$set(this, 'risiko_selected', selected)
      }else{
        let selected = _.clone(this.auto_risiko_selected)
        selected = _.uniq(selected)
        this.$set(this, 'risiko_selected', selected)
      }
    },
    doBindCKSub(row){
      const cek = _.indexOf(this.risiko_selected, row.id)
      if(cek>-1){
        this.risiko_selected.splice(cek, 1)
      }else{
        this.risiko_selected.push(row.id)
      }

      let notCheck = this.risiko_selected.length < this.t2_risikoData.length
      if(this.TRisikoSelect=='Y'){
        this.TRisikoSelect = notCheck?'N':'Y'
      }else{
        this.TRisikoSelect = !notCheck?'Y':'N'
      }
    },

    doSubmitRisiko(){
      let row = _.clone(this.row)
      row.risiko_selected = this.risiko_selected
      row.sebab_selected = this.sebab_selected
      row.kendali_selected = this.kendali_selected
      row.dampak_selected = this.dampak_selected
      this.doSubmit(
        "/do/"+this.modulePage, 
        _.assign(row, {
          type:'update',
          id: (this.pageId||this.pageSlug)
        }), 
        (type, resp)=>{
          if(type=='success'){
            this.$set(this,'mitigasi',0)
            this.$router.push({
              name:'Penaksiran', 
              query:{
                search:resp.data.row.trp_register_code, 
                tab:resp.data.row.trp_triwulan?'triwulan':'bulan'
              }
            })
          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        }
      )
    },
    doGetData(){
      if(this.pageSlug!='add' && this.$route.query.skip=='tab1'){
       this.loadingKhusus=true
      }
      this.apiGet({
        notLoading: this.loadingKhusus,
        callback:()=>{
          if(this.pageSlug!='add' && this.$route.query.skip=='tab1'){
            setTimeout(()=>{this.doNextTab()},300)
          }
        }
      });
    }
  },
  mounted(){
    this.doGetData()
  },
  watch: {
    t2_page(){
      this.getTab2('switchpage')
    },
    t3_page(){
      this.getTab3('switchpage')
    },

    $route(){
      this.stepTab=1
      this.doGetData()
    },
    mitigasi(v){
      if((v||0)>0){
        Swal.fire({
          title: "Terdapat "+v+" Tindakan Mitigasi Membutuhkan Konfirmasi!",
          icon: "warning",
          confirmButtonText: 'Konfirmasi Hasil Mitigasi',
          reverseButtons:true,
          allowEscapeKey:false,
          allowEnterKey:false,
          allowOutsideClick:false
        }).then((result) => {
          if(result.value){
            this.$router.push({name:'PenaksiranMitigasi', query:{status:'need-action', periode:(this.row.trp_tahun+'-'+this.row.trp_bulan) }})
          }
        })
      }
    },
    'row.trp_office_code'(v,o){
      if(v && o!==undefined){
        this.$set(this.row,'trp_unit_kerja',null)
      }
    },
    'row.trp_unit_kerja'(unit_kerja, old){
      if(old && old!=unit_kerja){
        this.risiko_selected=[]
        this.sebab_selected=[]
        this.dampak_selected=[]
        this.kendali_selected=[]
      }
    },
    modalTab(){
      this.$set(this,'input',{})
    },
    maxRisiko(v){
      if(!v && this.row.trp_is_periode=='bulan'){
        return Swal.fire({
          icon: "warning",
          title: "Tidak Bisa Menambah Register Risiko!",
          text: "Jumlah risiko sudah terpenuhi.",
          confirmButtonText: "Mengerti"
        }).then(()=>{
          this.$router.push({name:'Dashboard'})
        })
      }
    },
    periodeCurrent(v){
      if(v.status=='UPCOMING'){
        return Swal.fire({
          icon: "warning",
          title: "Tidak Bisa Menambah Register Risiko!",
          text: "Periode Bulanan belum dimulai, periode bulanan akan dibuka tanggal "+this.$moment(v.start_date).format('DD MMM YYYY')+".",
          confirmButtonText: "Mengerti"
        }).then(()=>{
          this.$router.push({name:'Dashboard'})
        })
      }
    }
  }
};
</script>

<style scoped>
.full-table-jmldata th{
  border-bottom:2px solid #59498c
}
</style>